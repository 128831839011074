<template>
  <div>
      <!-- <div class="row"> -->
<!--       
        <div class="col-12 d-flex align-items-center">
          <div class="d-flex b px-2 flex-column mr-2 align-items-center">
            <p
              class="m-0"
              style="font-weight: 400; font-size: 16px; color: #29292"
            >
              Started
            </p>
            <p
              class="m-0"
              style="font-weight: 600; font-size: 32px; color: #29292"
              v-if="mainAnalysis.start"
            >
              {{ mainAnalysis.start }}
            </p>
            <p
              class="m-0"
              style="font-weight: 600; font-size: 32px; color: #29292"
              v-else
            >
              -
            </p>
          </div>
          <div class="d-flex b px-2 flex-column mr-2 align-items-center">
            <p
              class="m-0"
              style="font-weight: 400; font-size: 16px; color: #29292"
            >
              Finished
            </p>
            <p
              class="m-0"
              style="font-weight: 600; font-size: 32px; color: #29292"
              v-if="mainAnalysis.submission"
            >
              {{ mainAnalysis.submission }}
            </p>
            <p
              class="m-0"
              style="font-weight: 600; font-size: 32px; color: #29292"
              v-else
            >
           
              -
            </p>
          </div>
          <div class="d-flex b px-2 flex-column mr-2 align-items-center">
            <p
              class="m-0"
              style="font-weight: 400; font-size: 16px; color: #29292"
            >
              Completion rate
            </p>
            <p
              class="m-0"
              style="font-weight: 600; font-size: 32px; color: #29292"
              v-if="mainAnalysis.submission && mainAnalysis.start"
            >
              {{ calculateCompletion }}%
            </p>
            <p
              class="m-0"
              style="font-weight: 600; font-size: 32px; color: #29292"
              v-else
            >
              -
            </p>
          </div>
          <div class="d-flex b px-2 flex-column mr-2 align-items-center">
            <p
              class="m-0"
              style="font-weight: 400; font-size: 16px; color: #29292"
            >
              Leads
            </p>
            <p
              class="m-0"
              style="font-weight: 600; font-size: 32px; color: #29292"
              v-if="mainAnalysis.totalLeads"
            >
              {{ mainAnalysis.totalLeads }}
            </p>
            <p
              class="m-0"
              style="font-weight: 600; font-size: 32px; color: #29292"
              v-else
            >
              -
            </p>
          </div>
        </div> -->
      <!-- </div> -->
     <!-- Toggle Buttons start -->
           <div
                class="w-100 d-flex justify-content-center align-items-center"
               
            >
                <div
                    class="d-flex align-items-center px-2 py-1"
                    style="border-radius: 6px; background: #ffffff"
                >
                    <button
                        @click="toggleTab('Insights')"
                        :class="
                            togglePageType == 'Insights'
                                ? 'toggleSelectedButton'
                                : 'toggleButton'
                        "
                        class="btn py-2"
                        style="width: 90px"
                    >
                        Quiz
                    </button>
                    <button
                        @click="toggleTab('Products')"
                        :class="
                            togglePageType == 'Products'
                                ? 'toggleSelectedButton'
                                : 'toggleButton'
                        "
                        class="btn py-2"
                        style="width: 90px"
                    >
                        Products
                    </button>
                </div>
                </div>
      <!-- your Quiz statics -->

      <div class="" v-if="togglePageType=='Insights'">
        
       
 
      
    
    
      
    </div> <!-- answer statics end-->
   
  </div>

</template>

<script>
// import ProductAnalytics from "./ProductAnalytics.vue";
import axios from "axios";
// import AnalyticsGraph from './AnalyticsGraph.vue';
// import AnalyticsTable from './AnalyticsTable.vue'
// import quizStatistics from "./quizStatistics.vue";
import { mapGetters } from "vuex";
// import CostStats from "./CostStats.vue";
// import LoaderComp from "../../customize/component/LoaderComp.vue";
export default {
  
  props: ["Token", "QuizKey","filter", "quizzes" ,"quizId","selectedDateValue"],
  components: {
//     quizStatistics,
// AnalyticsTable,
//     ProductAnalytics,
// AnalyticsGraph
    
    // CostStats,
    // LoaderComp,
  },
  data() {
    return {
      togglePageType:'Insights',
      isLoadingExport:false,
      isLoadingReset:false,
      QuizID: "",
      DateDropdown: [
        {
          text: "All",
          value: "all",
        },
        {
          text: "Last week",
          value: "lastWeek",
        },
        {
          text: "Last month",
          value: "lastMonth",
        },
        {
          text: "Custom Date",
          value: "customDate",
        },
      ],
      AnswerStats: [],
      mainAnalysis: {},
      graphAnalysis: [],
      isAnalysisLoading: true,
      isQuestionLoading:true,
    };
  },
  methods: {
    toggleTab(tab){
      this.togglePageType=tab
      this.$emit('updateSelectedTab',tab)
    },
        async resetChart(quizIds){
 
       this.isLoadingReset = true;
      
    // const response =
    const response = await this.$store.dispatch('fetchData',{
      axiosMethod:'post',
      resource:'analysis',
      method:'resetChart',
    params:{quizIds:quizIds}})
    if(response && response.status)
    {
      this.$refs.resetChartAnalytics.closeResetModal();
       this.$toasted.show("Stats has been reset.", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          this.getAnalyseData()
    }
    else
    {
      this.$toasted.show("Something went wrong.", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
    }
      this.isLoadingReset = false;
    },
        async resetQuiz(quizIds){
 
       this.isLoadingReset = true;
      
    // const response =
    const response = await this.$store.dispatch('fetchData',{
      axiosMethod:'post',
      resource:'analysis',
      method:'resetQuestions',
    params:{quizIds:quizIds}})
    if(response && response.status)
    {
       this.$toasted.show("Stats has been reset.", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          this.$refs.resetQuizAnalytics.closeResetModal()
          this.getAnalyseData()
    }
    else
    {
      this.$toasted.show("Something went wrong.", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
    }
      this.isLoadingReset = false;
    },
     async exportProduct(category){
    let data = {}
      if(category=='filtered')
      {
      let start_date = this.filter.start_date
      let end_date=this.filter.end_date,
      quizId=this.quizId
        if(this.selectedDateValue=='all')
      {
         start_date=""
          end_date=""
          quizId=[]
      }
        data = {
         startDate:start_date,
          endDate:end_date,
         quizId:quizId,
        
        };
      }
       this.isLoadingExport = true;
      
    const response = await this.$store.dispatch('fetchData',{
      axiosMethod:'post',
      resource:'analysis',
      method:'exportQuestions',
      params:data})
     const blob = new Blob([response], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          const downloadLink = this.$refs.downloadLink;
          downloadLink.href = url;
          downloadLink.download = 'AnswerAnalytics.csv';
          downloadLink.click();
          window.URL.revokeObjectURL(url);
      this.isLoadingExport = false;
      this.$refs.exportAnalysisProducts.closeExportModal()
    },
    async loadGraph(){
    
      this.isAnalysisLoading = true;
     await axios.post('/api/v1/quiz-analytics',this.payload).then(response=>{
      if(response.status==200)
      {
        this.mainAnalysis = response.data.data.main_analyses;
        this.graphAnalysis = response.data.data.graphic_analyses;
      }
     }).catch(e=>{throw e})
      this.isAnalysisLoading = false;
    },
    callbackFun(){
     
    if(this.togglePageType=='Products')
    this.$refs.products.getAnalyseData();
  this.getAnalyseData();
    this.loadGraph();
 
    },
    ResetCustomDate() {
      this.filter.start_date = null;
      this.filter.start_end  = null;
      this.selectedDateValue = { text: "All", value: "all" };
    },
    async getAnalyseData() {
      this.isQuestionLoading = true;
      try {    
        const response = await axios.post(`/api/v1/question-analytics`, this.payload);
        if (response.status == 200 && response.data.status == "success") {
          this.AnswerStats = response.data.data;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.isQuestionLoading = false;
      }
    },
  },
  computed: {
    payload(){
      let dataObject = {
  quizIds: this.quizId,
};
if (this.filter.start_date && this.filter.end_date) {
  dataObject.start_date = this.filter.start_date;
  dataObject.end_date = this.filter.end_date;
}
return dataObject
    },
    loadingSkeleton(){
      return ` <div>
      <div>
          <div class="row w-100">
            <div class="col-12">
              <div class="card text-left analyse-box mt-4 w-100">
                <div class="card-body">
                  <div
                    class="bg-preload mr-4"
                    style="height: 40px; width: 100px; border-radius: 4px"
                  >
                    <div class="into-preload"></div>
                  </div>
                 
                  <div
                    class="bg-preload mt-3"
                    style="height: 5px; width: 100%; border-radius: 4px"
                  >
                    <div class="into-preload"></div>
                  </div>
                  <div class="mt-4">
                    <div
                      class="bg-preload"
                      style="height: 500px; width: 100%; border-radius: 4px"
                    >
                      <div class="into-preload"></div>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>`
    },
    ...mapGetters(["getQuizID"]),
    calculateCompletion() {
      let value;
      if (this.mainAnalysis.start && this.mainAnalysis.submission) {
        value = Math.ceil(
          (this.mainAnalysis.submission / this.mainAnalysis.start) * 100
        );
      }

      return value > 100 ? 100 : value;
    },
    getQuizID() {
      return localStorage.getItem("QuizID");
    },
  },
  watch: {
  
  },
  mounted() {
    // this.getAnalyseData()
  },
  created() {
    this.QuizID = this.$store.state.QuizId;
   
    
  },
};
</script>

<style scoped>
.toggleButton {
    background: transparent;
    color: #292929;
    font-size: 14px;
    font-weight: 400px;
}
.toggleSelectedButton {
    background: rgba(77, 27, 126, 0.8) !important;
    color: #ffffff !important;
    font-size: 14px !important;
    font-weight: 500px !important;
}
.toggleSelectedButton:hover {
    color: #ffffff !important;
}
</style>
